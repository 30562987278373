.container {
    max-width: 420px;
    width: 100vw;
    height: 100vh;
    background: black;
    max-height: 760px;
    padding: 7px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
}
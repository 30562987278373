.container{
    display: flex;
    width: 100%;
    min-height: 40px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 12px;
    justify-content: space-between;
    font-family: Manrope;
}

.disable {
    background-color: #292252;
}

.active {
    background-color: rgba(53, 32, 91, 1);
    border: 1px solid #6537A8;
}

.dateTime{
    width: 70px;
    height: 100%;
    font-weight: 500;
    font-size: 10px;
    color: rgba(148, 138, 197, 1);
    display: flex;
    align-items: center;
    margin-left: 12px;
}
.moneyRatio{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 10%;
    box-sizing: border-box;
    align-items: center;
}
.cash{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #DFE5F2;
}
.ratio{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 26px;
    color: #FAFAFA;
    background: #3E5BC2;
    border-radius: 7px;
    font-size: 12px;
}
.win{
    width: 70px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 12px;
    font-size: 12px;
    color: #DFE5F2;
}

.betHistoryContentItemSmall {
    background: #3E5BC2;
}

.betHistoryContentItemMedium {
    background: #753EC2;
}

.betHistoryContentItemHight {
    background: #DE8B03;
}

@media (max-width: 330px){
    .moneyRatio{
        padding-right: 4%;
    }
    .cash{
        font-size: 11px;
    }
    .ratio{
        font-size: 11px;
    }
    .win{
        font-size: 11px;
    }
}

.container {
    /* Frame 14589 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px;
    margin: 8px 0;
    gap: 12px;
    box-sizing: border-box;

    position: relative;
    width: 100%;

    background: linear-gradient(90deg, #241D47 0%, #2B2357 100%);
    mix-blend-mode: normal;
    box-shadow: 0px -4.36152px 26.1691px rgba(0, 0, 0, 0.15);
    border-radius: 16px;

    font-family: Manrope;
}

.betInput {
    display: flex;
    flex-direction: column;
    width: calc(50% - 14px);
    background: #1B1536;
    padding: 4px;
    border-radius: 11px;
}

.counterContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;


    color: #DFE5F2;
    margin-bottom: 4px;
}

.counterButton {
    text-align: center;
    background: #231D47 no-repeat center;
    border-radius: 8px;
    width: 34px;
    height: 34px;
    cursor: pointer;
    user-select: none;
}

.minus {
    background-image: url('../../assets/minus.svg');
}

.plus {
    background-image: url('../../assets/plus.svg');
}

.plus:active {
    background: #1A1630 no-repeat center;
    background-image: url('../../assets/plus.svg');
    box-shadow: rgb(55 47 101 / 50%) 0px 5px 20px;
}

.minus:active {
    background: #1A1630 no-repeat center;
    background-image: url('../../assets/minus.svg');
    box-shadow: rgb(55 47 101 / 50%) 0px 5px 20px;
}

.line {
    height: 2px;
    width: calc(100% + 8px);
    margin-left: -4px;
    background: #231D47;
}

.addingBidsContainer {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    gap: 4px;
}

.bid {
    background: #231D47;
    color: #DFE5F2;

    width: 100%;
    border-radius: 7px;
    padding: 6px;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.bid p {
    font-size: 11px;
}

.bid:active {
    background: #1A1630 no-repeat center;
    box-shadow: rgb(55 47 101 / 50%) 0px 5px 20px;
}

.bringOutButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: linear-gradient(263.87deg, #FDBB4E 0%, #F56719 100%);
    box-shadow: 0 10px 20px -2px rgba(253, 187, 78, 0.1), 0 10px 20px -2px rgba(253, 187, 78, 0.1), inset 0px -2px 4px #FDBB4E, inset 0px -8px 8px rgba(253, 187, 78, 0.6);
    border-radius: 12px;

    border: 4px solid #1B1532;
    padding: 8px;
    box-sizing: border-box;

    width: calc(50% - 14px);
    height: 100%;

    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */
    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;

    flex-grow: 1;
    color: #FFFFFF;
    cursor: pointer;
    user-select: none;
}

.bringOutButton:active {
    opacity: 0.8;
}

.cancelButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 4px;

    border: 4px solid #1B1532;
    box-sizing: border-box;

    width: calc(50% - 14px);
    height: 100%;

    background: linear-gradient(263.87deg, #F54E76 0%, #FC244B 100%);
    box-shadow: 0px 10px 20px -2px rgba(245, 25, 65, 0.1), 0px 10px 20px -2px rgba(252, 36, 101, 0.1), inset 0px -2px 4px #F54E76, inset 0px -8px 8px rgba(245, 78, 128, 0.6);
    border-radius: 12px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;

    color: #FFFFFF;
    cursor: pointer;
    user-select: none;
}

.cancelButton:active {
    opacity: 0.8;
}

.putBetButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 4px;

    border: 4px solid #1B1532;
    box-sizing: border-box;

    width: calc(50% - 14px);
    height: 100%;

    background: linear-gradient(263.87deg, #944EF5 0%, #5C24FC 100%);
    box-shadow: 0 10px 20px -2px rgba(92, 36, 252, 0.1), inset 0 -2px 4px #944EF5, inset 0 -8px 8px rgba(148, 78, 245, 0.6);
    border-radius: 12px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;

    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;

    color: #FFFFFF;
    cursor: pointer;
    user-select: none;

    text-shadow: 0 0 20px #8A8A8A;
}

.putBetButton:active {
    opacity: 0.8;
}

.disabled {
    opacity: 0.7;
}

.input {
    border: 0px;
    background: transparent;
    text-align: right;
    outline:none;
    color: white;
    width: 60px;
    max-width: 60px;
    user-select: none;
}

@media (max-width: 365px) and (min-width: 290px){
    .input {
        width: 25px;
    }
}
.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 17px;

    background: #944EF5;
    border-radius: 12px;
    box-sizing: border-box;

    width: 100%;
    height: 50px;

    color: #FFFFFF;
    cursor: pointer;
    overflow: visible;

    font-family: Manrope;
}

.container:active {
    opacity: 0.7;
}
.container {
    height: 100%;
    min-height: 280px;
    background: #151028;
    width: 100%;
    border: 2px solid #261C4A;
    border-radius: 16px;
    box-sizing: border-box;
    padding: 10px;
    position: relative;
    overflow: hidden;
}

.background {
    position: absolute;
    left: 0;
    background-repeat: no-repeat;
    background-size: contain;
}

.backgroundLeft {
    width: 11px;
    height: calc(100% - 10px);
    top: 0;
    background-repeat: repeat-y;
    background-position-y: 0;
    background-image: url("../../assets/gameLayoutBackgroundLeft.svg");
    transition-delay: 0s;
}

.runningY {
    animation-duration: 4s;
    animation-name: RunningY;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.backgroundBottom {
    width: 100%;
    height: 11px;
    left: 10px;
    bottom: 0;
    background-repeat: repeat-x;
    background-image: url("../../assets/gameLayoutBackgroundbottom.svg");
    transition-delay: 0s;
}

.runningX {
    animation-duration: 4s;
    animation-name: RunningX;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.backgroundStars {
    width: 100%;
    height: 60%;
    top: 0;
    z-index: 0;
    background-size: contain;
    background-image: url("../../assets/star.svg");
    background-repeat: repeat;
    opacity: 0.3;
}


.graph {
    width: 500px;
    height: 100%;
    transform: scaleX(1.1);
    position: relative;
    left: 29px;
    bottom: 12px;
    background-repeat: no-repeat;
}

.graphImage {
    height: 10px;
    position: absolute;
    transform-origin: left bottom;
    left: 0;
    bottom: 0;
    object-fit: contain;
}

.jetPackContainer {
    position: relative;
}

.fire {
    position: absolute;
    height: 75px;
}

.fireContainer {
    height: 124px;
    width: 87px;
    position: absolute;
    left: -80px;
    bottom: -65px;
}

.betCount {
    position: absolute;
    z-index: 112;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 32px;
    font-family: RobotoMono;
    color: #944EF5;
}

.jetPackImage {
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 0;
    height: 124px;
}


.border {
    max-width: 400px;
    margin: 0 auto;
    position: relative;
    height: 100%;
}

.button {
    width: 80px;
    height: 40px;
    background: snow;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 130;
    bottom: -100px;
}

.button2 {
    bottom: -140px;
}

.button:active {
    background: antiquewhite;
}

.content {
    position: absolute;
    z-index: 2;
    left: 11px;
    bottom: -2px;
}

@keyframes RunningY {
    0% {
        background-position-y: 0;
    }

    100% {
        background-position-y: 220px;
    }
}

@keyframes RunningX {
    0% {
        background-position-x: 0;
    }

    100% {
        background-position-x: -220px;
    }
}
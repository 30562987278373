@font-face {
  font-family: 'RobotoMono';
  font-style: normal;
  src: local('RobotoMono'), url('./fonts/RobotoMono/RobotoMono-Bold.ttf') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  src: local('Manrope'), url('./fonts/Manrope/Manrope-Bold.ttf') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Halvar';
  font-style: normal;
  src: local('Halvar'), url('./fonts/Halvar/HalvarBreit-Bd.ttf') format('woff');
  font-weight: 700;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

p,
h1,
h2,
h3 {
  padding: unset;
  margin: unset;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.App {
  width: 100vw;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.animeBetCount {
  font-size: 48px;
  font-weight: 700;
}

/* This only changes this particular animation duration */
.animate__heartBeat,
.animate__bounce,
.animate__shakeY {
  --animate-duration: 2s;
}

/* This changes all the animations globally */
:root {
  --animate-duration: 800ms;
  --animate-delay: 0.9s;
}

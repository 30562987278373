.container{
    width: 65%;
    height: 75px;
    background-color: rgba(41, 33, 81, 1);
    border: 1px solid #944EF5;
    display: flex;
    padding: 12px;
    justify-content: space-around;
    border-radius: 12px;
    box-sizing: border-box;

    transition: .5s;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    user-select: none;
}

.text{
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.text p{
    width: 100%;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    color: #DFE5F2;
    height: 50%;
    margin: 0px ;
}

.text h2{
    width: 100%;
    height: 50%;
    font-size: 20px;
    line-height: 20px;
    margin: 0px;
    color: white;
}

.errorText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #DFE5F2;
    height: 100%;
    width: 100%;
}

.ratio{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    width: auto;
    padding: 22.5px 24px;
    background: linear-gradient(263.87deg, #944EF5 0%, #5C24FC 100%);
    box-shadow: 0px 10px 20px -2px rgba(92, 36, 252, 0.1);
    border-radius: 8px;
    box-sizing: border-box;
}
.ratio p{
    color: white;
    font-size: 16px;
    font-weight: 700;
}
@media (max-width:320px){
    .container{
        width: 90%;
    }
}


.die{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: calc(100% + 28px);
    height: 76px;
    bottom: 0;
    background-color: #221B43;
    padding: 0 55px;
    margin-left: -14px;
    box-sizing: border-box;
}
.text{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: white;
}
.darken{
    color: rgba(223, 229, 242, 0.7);
}
.Image_1{
    rotate: 180deg;
}
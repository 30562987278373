.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 111;
    font-family: Halvar;
}

.loaderContainer {
    position: relative;
    height: 63px;
    width: 63px;
}

.circleContainer {
    position: absolute;
    top: 0;
}

.circle {
    position: relative;
    height: 63px;
    width: 63px;
}

.insideCircle,
.outsideCircle {
    transition-delay: 0s;
    animation-name: load;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: absolute;
    transform-origin: center;
}

.outsideCircle {
    animation-duration: 5s;
}

.insideCircle {
    animation-duration: 4s;
    top: 6px;
    left: 10px;
}

.rocketContainer {
    position: relative;
    height: 33px;
    width: 32px;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.rocket,
.fires {
    transition-delay: 0s;
    animation-duration: 1s;
    animation-name: bounce;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: absolute;
    transform-origin: center;
}

.fires {
    animation-name: bounce-reverse;
    bottom: 0;
    left: 8px;
}

.title {
    color: white;
    font-size: 24px;
    text-align: center;
    width: 290px;
}

.line {
    margin-top: 17px;
    width: 213px;
    height: 6px;

    background: rgba(255, 255, 255, 0.1);
    border-radius: 24px;
    position: relative;
}

.line::after {
    /* Rectangle 2233 */

    content: '';
    position: absolute;
    width: 0;
    height: 4px;
    left: 1px;
    top: 1px;

    background: #944EF5;
    box-shadow: 0 2px 9px rgba(148, 78, 245, 0.25);
    border-radius: 24px;

    animation-duration: 8s;
    animation-name: line;
    animation-timing-function: linear;
}

@keyframes line {
    0% {
        width: 211px;
    }

    100% {
        width: 0;
    }
}

@keyframes bounce {
    0% {
        transform: translateY(-2px);
    }

    50% {
        transform: translateY(1px);
    }

    100% {
        transform: translateY(-2px);
    }
}

@keyframes bounce-reverse {
    0% {
        transform: translateY(2px);
    }

    50% {
        transform: translateY(1px);
    }

    100% {
        transform: translateY(2px);
    }
}

@keyframes load {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(720deg);
    }
}
.header{
    background: #151028;
    border-radius: 12px;
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px;
    font-family: Manrope;
}

.userInfo {
    height: 54px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.betHistory {
    width: 100%;
    position: relative;
}

.betHistoryContent {
    width: 100%;
    max-width: 100%;
    height: 34px;
    display: flex;
    align-items: start;
    gap: 4px;
    padding: 4px;
    color: white;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-scrolling: touch;
    user-select: none;
    position: relative;
}

/* hide scrollbar but allow scrolling */
.betHistoryContent {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}

.betHistoryContent::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.header img{
    margin-left: 8px;
    width: 30%;
}

.cash_record {
    height: 38px;
    background: #110D21;
    border-radius: 10px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    padding: 4px;
    box-sizing: border-box;
}
.cash{
    display: flex;
    align-items: center;
    border-radius: 8px;
    background: #231D47;
    color: white;
    min-width: 85px;
    padding-left: 8px;
    height: 30px;
    box-sizing: border-box;
}

.cash img{
    height: 13px;
    margin-left: 0;
}

.line {
    width: 2px;
    height: 16px;
    background: #261F4D;
    border-radius: 1px;
    margin: 0 8px;
}

.cash h3{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    flex-grow: 3;
    padding: 0 7px;
}

.max_ratio{
    display: flex;
    align-items: center;
    height: 26px;
}
.ratio{
    display: flex;
    align-items: center;
    border-radius: 8px;
    background: rgba(222, 139, 3, 1);
    color: white;
    height: 100%;
    padding: 0px 10px;       
    font-size: 12px;
}

.Text{
    color: white;
    font-size: 11px;
    margin-right: 4px;
}

.betHistoryContentItem {
    height: 26px;
    width: 58px;
    min-width: 58px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.betHistoryContentItemSmall {
    background: #3E5BC2;
}

.betHistoryContentItemMedium {
    background: #753EC2;
}

.betHistoryContentItemHight {
    background: #DE8B03;
}

.betHistoryMoreButton {
    position: absolute;
    right: 11px;
    top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: #231D47;
    border-radius: 8px;
}

.betHistoryMoreButton img {
    height: 9px;
    margin: 0;
}

@media (max-width: 320px) and (min-width: 290px){
    .ratio{
        font-size: 11px;
    }
    .cash h3{
        font-size: 11px;
    }
    .Text{
        font-size: 9px;
    }
}
@media (max-width: 289px){
    .ratio{
        font-size: 9px;
    }
    .cash h3{
        font-size: 9px;
    }
    .Text{
        font-size: 9px;
    }
}
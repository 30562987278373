.container {
    max-width: 420px;
    width: 100vw;
    height: 100vh;
    background: #261C4A;
    max-height: 760px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 14px;
}

.bidsList {
    height: calc(100% - 124px);
    overflow: hidden;
    gap: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.bidsHeader {
    align-items: center;
    height: 46px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title {
    color: white;
    font-size: 14px;
    font-family: Halvar;
    letter-spacing: 0.06em;
    line-height: 18px;
}